import { Dropdown, Menu, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { CircleFlag } from "react-circle-flags";
import { Translate } from "../../function/Common.js";
function Language() {
  const [isLang, setIsLang] = useState(
    localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
  );

  function handleChangeLanguag(e) {
    localStorage.setItem("lang", e);
    window.location.reload();
  }
  return (
    <div className="select-language">
      <Select defaultValue={isLang} onChange={handleChangeLanguag}>
        <Select.Option value="en">
          <div>
            <CircleFlag countryCode="us" height="20" />
            &nbsp;{Translate("English")}
          </div>
        </Select.Option>

        <Select.Option value="cn">
          <div>
            <CircleFlag countryCode="cn" height="20" />
            &nbsp; {Translate("Chinese")}
          </div>
        </Select.Option>

        <Select.Option value="my">
          <div>
            <CircleFlag countryCode="my" height="20" />
            &nbsp; {Translate("Malay")}
          </div>
        </Select.Option>
      </Select>
    </div>
  );
}

export default Language;
