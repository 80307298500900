import { Carousel, Image } from "antd";
import React from "react";
import "./buildfortommorow.scss";
import FirstPage from "../../images/first-back.png";
import { Translate } from "../../function/Common";
function BuildForTommorow() {
  return (
    <div className="build-tommorow">
      <Carousel autoplay>
        <div className="carousel">
          <img src={FirstPage} alt="1" />
          <div className="text-container">
            <div className="text-build-tommorow">
              <p className="build">{Translate("build")}</p>
              <p className="build for-tmrw">{Translate("fortommorow")}</p>
              <p className="desc-build-tommorow">
                {Translate(
                  "BersatuGrouparepoweredbyapassionateteamthataspiretobringamazingconcepttolikeDrivenbyourslogonofBuildForTommorowweintenttobringdevelopmentanddeliversustainablefuturestoourlocalcommunities"
                )}{" "}
              </p>
            </div>
          </div>
        </div>
        <div>
          <img src={FirstPage} alt="2" />
          <div className="text-container">
            <div className="text-build-tommorow">
              <p className="build">{Translate("build")}</p>
              <p className="build for-tmrw">{Translate("fortommorow")}</p>
              <p className="desc-build-tommorow">
                {Translate(
                  "BersatuGrouparepoweredbyapassionateteamthataspiretobringamazingconcepttolikeDrivenbyourslogonofBuildForTommorowweintenttobringdevelopmentanddeliversustainablefuturestoourlocalcommunities"
                )}{" "}
              </p>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
}

export default BuildForTommorow;
