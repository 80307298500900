import React, { useContext } from "react";
import { Route } from "react-router-dom";
import Branding from "../branding/Branding";
import Charity from "../charity/Charity";
import Contact from "../contact/Contact";
import Development from "../development/Development";
import Hospitality from "../hospitality/Hospitality";
import Latestnews from "../latestnews/Latestnews";
import Logistic from "../logistic/Logistic";
import Main from "../main-page/Main";
import Plantation from "../plantation/Plantation";
import LatestnewsInfo from "../latestnews/LatestnewsInfo";

const routes = [
  {
    path: "/charity",
    component: Charity,
    key: "charity",
  },
  {
    path: "/development",
    component: Development,
    key: "development",
  },
  {
    path: "/logistic",
    component: Logistic,
    key: "Logistic",
  },
  {
    path: "/plantation",
    component: Plantation,
    key: "plantation",
  },
  {
    path: "/branding",
    component: Branding,
    key: "branding",
  },
  {
    path: "/hospitality",
    component: Hospitality,
    key: "hospitality",
  },
  {
    path: "/contactus",
    component: Contact,
    key: "contactus",
  },
  {
    path: "/latestnews",
    component: Latestnews,
    key: "latestnews",
  },
  {
    path: "/latestnews/info",
    component: LatestnewsInfo,
    key: "latestnewsinfo",
  },
];

export default function RouteList() {
  return routes.map((items) => {
    if (items.path.split("/".length === 2)) {
      return (
        <Route
          exact
          path={items.path}
          component={items.component}
          key={items.key}
        />
      );
    }
    <Route
      exact
      path={items.path}
      component={items.component}
      key={items.key}
    />;
  });
}
