import { Layout } from "antd";
import React from "react";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import RouteList from "../route/RouteList";

function Main() {
  const { Content } = Layout;
  return (
    <div className="main-page">
      <Layout
        style={{ width: "100%", minHeight: "100vh", backgroundColor: "black" }}
      >
        <Navbar />

        <Content>
          <RouteList />
        </Content>

        <Layout
          style={{
            backgroundColor: "black",
          }}
        >
          <Footer />
        </Layout>
      </Layout>
    </div>
  );
}

export default Main;
