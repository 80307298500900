import { Button, Col, Drawer, Menu, Row } from "antd";
import React, { useState } from "react";
import "./navbar.scss";
import { BsFacebook } from "react-icons/bs";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import Logo from "../../images/logo.png";
import { useHistory } from "react-router-dom";
import Language from "./Language";
import { Translate } from "../../function/Common.js";

function Navbar() {
  const [openMenu, setOpenMenu] = useState(false);
  const history = useHistory();

  const DrawerMenuItem = [
    {
      label: Translate("Home"),
      key: "home",
      onClick: () => {
        history.push("/");
      },
    },
    {
      label: Translate("aboutus"),
      key: "aboutus",
      onClick: () => {
        history.push("/");
      },
    },
    {
      label: "Page",
      type: "group",
      key: "business",
      children: [
        {
          label: Translate("ourbusiness"),
          key: "our",
          children: [
            {
              label: Translate("development"),
              onClick: () => {
                history.push("/development");
              },
            },
            {
              label: Translate("branding"),
              onClick: () => {
                history.push("/branding");
              },
            },
            {
              label: Translate("plantation"),
              onClick: () => {
                history.push("/plantation");
              },
            },
            {
              label: Translate("hospitality"),
              onClick: () => {
                history.push("/hospitality");
              },
            },
            {
              label: Translate("logistic"),
              onClick: () => {
                history.push("/logistic");
              },
            },
          ],
        },
      ],
    },
    {
      label: Translate("charity"),
      key: "charity",
      onClick: () => {
        history.push("/charity");
      },
    },
    {
      label: Translate("contactus"),
      key: "contactus",
      onClick: () => {
        history.push("/contactus");
      },
    },
    {
      label: <BsFacebook />,
      key: "facebook",
      onClick: () => {
        window.open("https://web.facebook.com/Bersatugroup7997");
      },
    },
    {
      label: <Language />,
      key: "language",
    },
  ];

  return (
    <Row justify="center" className="navbar-container">
      <Col xxl={11} xl={10} lg={2} sm={12} xs={12}>
        <div className="navbar-left">
          <img src={Logo} alt="logo" onClick={() => history.push("/")} />
        </div>
      </Col>

      <Col xxl={13} xl={14} lg={22} sm={0} xs={0} className="navbar-right">
        <Row justify="center">
          <Col xl={3} lg={3} xs={6}>
            <div className="navbar-menu home" onClick={() => history.push("/")}>
              {Translate("Home")}
            </div>
          </Col>
          <Col xl={3} lg={3} xs={6}>
            <div className="navbar-menu aboutus">
              {Translate("aboutus")}
              <div className="dropdown-content-aboutus"></div>
            </div>
          </Col>
          <Col xl={4} lg={3} xs={12} className="navbar-menu business">
            <div>{Translate("ourbusiness")}</div>
            <div className="dropdown-content-business">
              <div>
                <div
                  className="business-menu"
                  onClick={() => history.push("/development")}
                >
                  {Translate("development")}
                </div>
                <div
                  className="business-menu"
                  onClick={() => history.push("/branding")}
                >
                  {Translate("branding")}
                </div>
                <div
                  className="business-menu"
                  onClick={() => history.push("/plantation")}
                >
                  {Translate("plantation")}
                </div>
                <div
                  className="business-menu"
                  onClick={() => history.push("/hospitality")}
                >
                  {Translate("hospitality")}
                </div>
                <div
                  className="business-menu"
                  onClick={() => history.push("/logistic")}
                >
                  {Translate("logistic")}
                </div>
              </div>
            </div>
          </Col>

          <Col xl={3} lg={3} xs={6}>
            <div
              className="navbar-menu charity"
              onClick={() => history.push("/charity")}
            >
              {Translate("charity")}
            </div>
          </Col>
          <Col xl={3} lg={3} xs={12}>
            <div
              className="navbar-menu contact"
              onClick={() => history.push("/contactus")}
            >
              {Translate("contactus")}
            </div>
          </Col>
          <Col xl={3} lg={3} xs={12}>
            <div
              className="navbar-menu contact"
              onClick={() => history.push("/latestnews")}
            >
              {Translate("latestnews")}
            </div>
          </Col>

          <Col xl={2} lg={2} xs={4}>
            <div
              className="navbar-menu facebook"
              onClick={() =>
                window.open("https://web.facebook.com/Bersatugroup7997")
              }
            >
              <BsFacebook />
            </div>
          </Col>

          <Col xl={3} lg={3} xs={4}>
            <div className="navbar-menu language">
              <Language />
            </div>
          </Col>
        </Row>
      </Col>

      <Col xxl={0} xl={0} lg={0} sm={12} xs={12}>
        <div className="navbar-menu-small">
          <Button
            icon={<MenuUnfoldOutlined />}
            style={{ backgroundColor: "#b38e1f", border: "1px solid black" }}
            onClick={() => setOpenMenu(!openMenu)}
          ></Button>
        </div>

        <Drawer
          width={300}
          closable={false}
          onClose={() => setOpenMenu(false)}
          open={openMenu}
          bodyStyle={{ backgroundColor: "#001529", padding: "0" }}
        >
          <Menu theme="dark" mode="inline" items={DrawerMenuItem} />
        </Drawer>
      </Col>
    </Row>
  );
}

export default Navbar;
