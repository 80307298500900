import { Col, Row } from "antd";
import React from "react";
import { Translate } from "../../function/Common";
import "./logistic.scss";

import LogisticCompany from "../../images/logistic-company.png";
function Logistic() {
  return (
    <div className="logistics-container">
      <div className="logistic-image">
        <div>
          <Row gutter={20} justify="center" className="logistic-content">
            <Col
              xxl={5}
              lg={6}
              sm={7}
              xs={13}
              className="logistic-title-container"
            >
              <div className="title logistic-title">
                {Translate("YOURBUSINESSCOMPANION")}
              </div>
              <div className="line-divider"></div>
              <div className="desc logistic-title">
                {Translate("YOURBUSINESSCOMPANIONDESC")}
              </div>
            </Col>

            <Col
              xxl={5}
              lg={6}
              sm={7}
              xs={13}
              className="logistic-title-container"
            >
              <div className="title logistic-title">
                {Translate("SERVICES")}
              </div>
              <div className="line-divider"></div>
              <div className="desc logistic-title">
                {Translate("SERVICESDESC")}
              </div>
            </Col>

            <Col
              xxl={5}
              lg={6}
              sm={7}
              xs={13}
              className="logistic-title-container"
            >
              <div className="title logistic-title">
                {Translate("FLEXIBLE")}
              </div>
              <div className="line-divider"></div>
              <div className="desc logistic-title">
                {Translate("FLEXIBLEDESC")}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="logistic-bottom-desc">
        <div className="logistic-desc">{Translate("logistic")}</div>
        <div className="logistic-desc2">{Translate("logisticdesc")}</div>
      </div>

      <Row justify="center" className="logistic-container">
        <Col xl={8} sm={6} xs={24}>
          <img src={LogisticCompany} alt="bglogitstic" />
        </Col>
        <Col className="desc-title-logistic" xl={16} sm={18} xs={24}>
          <div className="title-logistic">BG LOGISTICS SDN BHD</div>
          <div className="desc-logistic">{Translate("logisticdesc")}</div>
          <Row className="desc-button">
            <Col xs={24}>
              <button
                className="button"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/profile.php?id=100064106169948"
                  )
                }
              >
                {Translate("findoutmore")}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <div className="line-divider"></div>
        </Col>
      </Row>
    </div>
  );
}

export default Logistic;
