import { Col, Row } from "antd";
import React from "react";
import "./bersatuvideo.scss";
function BersatuVideo() {
  return (
    <div className="video-bersatu">
      <Row justify="center">
        <Col xs={24}>
          <video
            src="https://bersatu.sgp1.digitaloceanspaces.com/Baminton.mov"
            controls
            autostart
            autoPlay
            muted
            loop
            style={{ width: "100%" }}
            type="video/mp4"
          />
        </Col>
      </Row>
    </div>
  );
}

export default BersatuVideo;
