import { Col, Row } from "antd";
import React from "react";
import { Translate } from "../../function/Common";
import "./charity.scss";
import FoodDistribution from "../../images/fooddistribution.png";
import CharityDesc from "./charitydesc/CharityDesc";
function Charity() {
  return (
    <div className="charitys-container">
      <div className="charity-image"></div>
      <div className="charity-bottom-desc">
        <div className="charity-desc">YS CHARITY FOUNDATION</div>
        <div className="charity-desc2">{Translate("ysdesc")}</div>
      </div>
      <Row justify="center">
        <Col sm={5} xs={11} className="food-distribution-container">
          <div>
            <img src={FoodDistribution} alt="fooddistribution" />
          </div>
          <div className="title">{Translate("FOODDISTRIBUTIONPROGRAMME")}</div>
          <div className="desc">
            {Translate("FOODDISTRIBUTIONPROGRAMMEDESC")}
          </div>
        </Col>
        <Col sm={5} xs={11} className="food-distribution-container">
          <div>
            <img src={FoodDistribution} alt="fooddistribution" />
          </div>
          <div className="title">
            {Translate("FACEMASKMEDICALSUPPLIESPROGRAMME")}
          </div>
          <div className="desc">
            {Translate("FACEMASKMEDICALSUPPLIESPROGRAMMEDESC")}
          </div>
        </Col>

        <Col sm={5} xs={24} className="food-distribution-container">
          <div>
            <img src={FoodDistribution} alt="fooddistribution" />
          </div>
          <div className="title">{Translate("BLOODDONATIONEVENT")}</div>
          <div className="desc">{Translate("BLOODDONATIONEVENTDESC")}</div>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <div className="line-divider"></div>
        </Col>
      </Row>

      <CharityDesc />
    </div>
  );
}

export default Charity;
