import "./App.css";
import Home from "./pages/home-page/Home";
import Navbar from "./pages/navbar/Navbar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Charity from "./pages/charity/Charity";
import Development from "./pages/development/Development";
import Main from "./pages/main-page/Main";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          <Route>
            <Main />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
