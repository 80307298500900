import React from "react";
import "./footer.scss";
import { Translate } from "../../function/Common";
import Logo from "../../images/logo.png";
import { Col, Row } from "antd";

function Footer() {
  return (
    <Row className="footer-container">
      <Col xxl={6} xs={24}>
        <div className="logo-image">
          <img src={Logo} alt="logo-footer" />
        </div>
      </Col>
      <Col xxl={18} className="desc">
        <Row>
          <Col xxl={8} xs={24}>
            <div className="location-hour-container">
              <div className="location-container">
                <div>{Translate("location")} :</div>

                <div className="desc-location">
                  Bersatu To Holding Sdn Bhd 1-2 Atas, Jalan Sutera 1/2, Taman
                  Sungai Abong, 84000 Muar, Johor.
                </div>
              </div>
              <div className="location-container">
                <div>{Translate("businesshour")} :</div>

                <div className="desc-location">
                  <div>{Translate("mondaytosaturday")}</div>
                  <div> 09:00 - 17:30 (Effective from 01/09/2022)</div>
                </div>
              </div>
            </div>
          </Col>
          <Col xxl={8} xs={24}>
            <div className="location-hour-container">
              <div className="location-container">
                <div>{Translate("contactus")} :</div>

                <div className="desc-location">
                  <div>010 806 7997</div>
                  <div>06 959 2462 | 06 959 2051</div>
                  <div>enquiry@bersatugroup.com.my job@bersatugroup.com.my</div>
                </div>
              </div>
            </div>
          </Col>
          <Col xxl={8} xs={24}>
            <div className="location-hour-container">
              <div className="location-container">
                <div>Copyright © 2021 Bersatu To Holding Sdn Bhd</div>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Footer;
