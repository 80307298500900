import { Layout } from "antd";
import React from "react";
import { Route } from "react-router-dom";
import Appointment from "../appointment/Appointment";
import BersatuVideo from "../bersatu-video/BersatuVideo";
import BuildForTommorow from "../BuildForTommorow/BuildForTommorow";
import Footer from "../footer/Footer";
import Introduce from "../introduce/Introduce";
import Navbar from "../navbar/Navbar";

import "./home.scss";
function Home() {
  const { Content } = Layout;

  return (
    <div className="home-page">
      <Navbar />
      <BuildForTommorow />
      <BersatuVideo />
      <Introduce />
      <Appointment />
      <Footer />
    </div>
  );
}

export default Home;
