import { CCarousel, CCarouselItem, CImage } from "@coreui/react";
import { Card, Col, Row } from "antd";
import React, { useState } from "react";
import { ReactImageCarouselViewer } from "react-image-carousel-viewer";
import { useHistory } from "react-router-dom";
import { Translate } from "../../function/Common";
import Badmintion1 from "../../images/badminton.png";
import Badmintion2 from "../../images/badminton2.png";
import "./latestnewsinfo.scss";
function LatestnewsInfo() {
  const [showImage, setShowImage] = useState(false);
  const [index, setIndex] = useState(0);
  const arrLatestnewsInfoImage = [
    { src: Badmintion1, desc: "1" },
    { src: Badmintion2, desc: "2" },
  ];
  const history = useHistory();

  return (
    <div className={`latest-news-info-container ${showImage && "active"} `}>
      <Row justify="center">
        <Col lg={16} xs={23}>
          <Row justify="center">
            <Col xs={24}>
              <h1>{Translate("LatestnewsInfoTitle")}</h1>
            </Col>
            <Col xs={24}>
              <div className="LatestnewsInfoDesc">
                {Translate("LatestnewsInfoDesc")}
              </div>
            </Col>
          </Row>

          <Row justify="center" gutter={20} style={{ paddingBottom: "40px" }}>
            {arrLatestnewsInfoImage?.map((items, index) => {
              return (
                <Col xl={8} lg={12} sm={12} xs={24} key={items}>
                  <Card>
                    <div className="latest-news-info-image">
                      <img
                        src={items.src}
                        alt="items"
                        onClick={() => {
                          setIndex(index);
                          setShowImage(!showImage);
                        }}
                      />
                    </div>
                  </Card>
                </Col>
              );
            })}

            <ReactImageCarouselViewer
              open={showImage}
              onClose={() => setShowImage(false)}
              images={arrLatestnewsInfoImage}
              startIndex={index}
            />
          </Row>

          <Row justify="center">
            <Col>
              <div
                className="back-to-list"
                onClick={() => history.push("/latestnews")}
              >
                {Translate("backToList")}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default LatestnewsInfo;
