import { Button, Card, Col, Row } from "antd";
import React from "react";
import "./charitydesc.scss";
import { BsFacebook } from "react-icons/bs";
import charityfirst from "../../../images/charity-first.jpg";
function CharityDesc() {
  return (
    <div className="charity-desc-container">
      <Row>
        <Col xs={24} className="charity-desc-wrapper">
          <Card style={{ borderRadius: "15px" }}>
            <Row>
              <Col xxl={18} className="charity-desc-desc">
                <div className="title">
                  YS CHARITY | AKTIVITI KEMASYARAKATAN DI PERMAS JAYA, JOHOR
                  BAHRU
                </div>
                <div className="title2">
                  PUSAT PEMULIHAN DALAM KOMUNITI (PDK PERMAS JAYA) / JULY17,
                  2022
                </div>
                <div className=" paragraph paragraph1">
                  YS Charity, dengan kerjasama PDK PERMAS JAYA dan Jabatan
                  Kebajikan Masyarakat Johor Bahru telah menjalankan aktiviti
                  gotong royong pembersihan kawasan komuniti di samping
                  menyediakan pemeriksaan kesihatan percuma kepada penduduk
                  setempat serta mengedarkan sumbangan keperluan asas untuk
                  membantu penduduk dalam mewujudkan persekitaran hidup sihat
                  khususnya bagi kanak-kanak kurang upaya.
                </div>
                <div className="paragraph paragraph2">
                  Kanak-kanak istimewa dan kanak-kanak kurang upaya kebiasaanya
                  kurang mendapat perhatian dari segi pengagihan sumbangan, dan
                  mereka sering menghadapi isu seperti kekurangan sumber
                  keperluan hidup dan pendidikan. YS Charity dengan ini merayu
                  kepada orang ramai dari semua lapisan masyarakat untuk memberi
                  perhatian yang lebih dekat kepada keperluan kumpulan yang
                  terjejas ini dan cuba untuk bantu semampu boleh.
                </div>
                <div className="paragraph paragraph3">
                  Biarkan cinta menjadi cahaya petunjuk selamanya buat mereka.
                </div>
                <div className="hashtag-container">
                  <div className="hashtag">#YsCharitySentiasaBersamaAnda</div>
                  <div className="hashtag">#KeluargaSihatHidupBahagia</div>
                  <div className="hashtag">#PDKPermasJaya</div>
                  <div className="hashtag">#YsCharityFoundation</div>
                  <div className="hashtag">#KeluargaSihatAndaBahagia</div>
                </div>
                <Row>
                  <Col xs={4} className="button-container">
                    <button
                      onClick={() =>
                        window.open(
                          "https://www.facebook.com/permalink.php?story_fbid=205880968433272&id=110046861350017"
                        )
                      }
                    >
                      <div className="button-follow">
                        <div className="icon">
                          <BsFacebook />
                        </div>
                        <div>FOLLOW US</div>
                      </div>
                    </button>
                  </Col>
                </Row>
              </Col>
              <Col xxl={6} className="charity-desc-image">
                <img src={charityfirst} alt="1" />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default CharityDesc;
