import { language } from "../pages/navbar/LanguageConf";

export function Translate(value) {
  const langs = localStorage.getItem("lang");
  // const languages = 'ZH';
  var lang;
  switch (langs) {
    case "en":
      lang = "en";
      break;
    case "my":
      lang = "my";
      break;
    case "cn":
      lang = "cn";
      break;
    default:
      lang = "en";
  }

  return language[lang][value];
}
