import { Col, Row } from "antd";
import React from "react";
import "./plantation.scss";
import PlantationCompany from "../../images/plantation-company.png";
function Plantation() {
  return (
    <div className="plantations-container">
      <div className="plantation-image">
        <div>
          <Row justify="center" className="plantation-content">
            <Col
              xxl={5}
              lg={6}
              sm={7}
              xs={13}
              className="plantation-title-container"
            >
              <div className="title plantation-title">PRESERVE</div>
              <div className="line-divider"></div>
              <div className="desc plantation-title">
                BG Plantation committed to preserve the ecosystem and maintain
                the balance between harvest and nature.
              </div>
            </Col>

            <Col
              xxl={5}
              lg={6}
              sm={7}
              xs={13}
              className="plantation-title-container"
            >
              <div className="title plantation-title">RESPONSIBILITY</div>
              <div className="line-divider"></div>
              <div className="desc plantation-title">
                Caretakers of plantation are the key to promote the harvest of
                the plantation thus we respect and value the hard work of our
                staff.
              </div>
            </Col>

            <Col
              xxl={5}
              lg={6}
              sm={7}
              xs={13}
              className="plantation-title-container"
            >
              <div className="title plantation-title">OUR PRODUCT</div>
              <div className="line-divider"></div>
              <div className="desc plantation-title">
                We engage in oil palm and fruits plantation and distribute it to
                local market.
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="plantation-bottom-desc">
        <div className="plantation-desc">PLANTATION</div>
        <div className="plantation-desc2">Planting the future.</div>
      </div>

      <Row justify="center" className="plantation-container">
        <Col xl={8} sm={6} xs={24}>
          <img src={PlantationCompany} alt="bgplantation" />
        </Col>
        <Col className="desc-title-plantation" xl={16} sm={18} xs={24}>
          <div className="title-plantation">
            BERSATU CAPITAL PLANTATION SDN BHD
          </div>
          <div className="desc-plantation">
            We committed to preserve the ecosystem and maintain the balance
            between harvest and nature.
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <div className="line-divider"></div>
        </Col>
      </Row>
    </div>
  );
}

export default Plantation;
