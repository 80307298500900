import { Col, Row } from "antd";
import React from "react";
import GoogleMapReact from "google-map-react";
import "./contact.scss";
import Appointment from "../appointment/Appointment";
import { Translate } from "../../function/Common";

import ContactUs from "../../images/contactus.jpg";
function Contact() {
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 20,
  };

  return (
    <div className="contacts-container">
      <div className="contact-image">
        <div>
          <Row justify="center" className="contact-content">
            <Col xxl={24} xs={24} className="contact-title-container">
              <div className="title contact-title">
                {Translate("contactus")}
              </div>
              <div className="desc contact-title">
                {Translate("contactusdesc")}
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Row className="whole-our-office">
        <Col
          xxl={12}
          xl={12}
          lg={12}
          sm={12}
          xs={24}
          className="our-office-container"
        >
          <div className="title">{Translate("ouroffice")}</div>
          <div className="desc">
            Our business hours are from 09:00 to 18:00,
            {Translate("mondaytosaturday")}.
          </div>

          <div className="desc2">
            Tel: 06 959 2462 | 06 959 2051 010 806 7997
          </div>
        </Col>
        <Col xxl={12} xl={12} lg={12} sm={12} xs={24}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyArQ4O-UxXWRziJgjYKquiEopzWpHh1EYc",
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          ></GoogleMapReact>
        </Col>
      </Row>

      <Appointment />

      <Row>
        <Col xs={24}>
          <div className="line-divider"></div>
        </Col>
      </Row>
    </div>
  );
}

export default Contact;
