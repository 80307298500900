import { Col, Row } from "antd";
import React from "react";
import Charity from "../../images/charity.png";
import CharityHover from "../../images/charity-hover.png";
import Logistic from "../../images/logistic.png";
import DevelopmentHover from "../../images/development-hover.png";
import PlantationHover from "../../images/plantation-hover.png";
import LogisticHover from "../../images/logistic-hover.png";
import HostapilityHover from "../../images/hostapility-hover.png";
import BrandingHover from "../../images/branding-hover.png";
import "./introduce.scss";
import { useHistory } from "react-router-dom";
import { Translate } from "../../function/Common";
function Introduce() {
  const history = useHistory();
  return (
    <div className="introduce">
      <Row gutter={[5, 5]}>
        <Col xl={12} xs={24}>
          <div className="container">
            <div className="img-introduce charity">
              <img src={Charity} alt="charity" />
            </div>
            <div className="overlay" onClick={() => history.push("/charity")}>
              <img src={CharityHover} alt="CharityHover" />
            </div>
          </div>
        </Col>

        <Col xl={12} xs={24}>
          <div
            className="container"
            onClick={() => history.push("/development")}
          >
            <div className="img-introduce development">
              <img src={Logistic} alt="development" />
            </div>
            <div className="desc-introduce development">
              {Translate("development")}
            </div>
            <div className="overlay">
              <img src={DevelopmentHover} alt="CharityHover" />
            </div>
          </div>
        </Col>

        <Col xl={12} xs={24}>
          <div className="container" onClick={() => history.push("/logistic")}>
            <div className="img-introduce development">
              <img src={Logistic} alt="development" />
            </div>
            <div className="desc-introduce development">
              {Translate("logistic")}
            </div>
            <div className="overlay">
              <img src={LogisticHover} alt="CharityHover" />
            </div>
          </div>
        </Col>

        <Col xl={12} xs={24}>
          <div
            className="container"
            onClick={() => history.push("/plantation")}
          >
            <div className="img-introduce development">
              <img src={Logistic} alt="development" />
            </div>
            <div className="desc-introduce development">
              {Translate("plantation")}
            </div>
            <div className="overlay">
              <img src={PlantationHover} alt="CharityHover" />
            </div>
          </div>
        </Col>

        <Col xl={12} xs={24}>
          <div className="container" onClick={() => history.push("/branding")}>
            <div className="img-introduce development">
              <img src={Logistic} alt="development" />
            </div>
            <div className="desc-introduce development">
              {Translate("branding")}
            </div>
            <div className="overlay">
              <img src={BrandingHover} alt="CharityHover" />
            </div>
          </div>
        </Col>

        <Col xl={12} xs={24}>
          <div
            className="container"
            onClick={() => history.push("/hospitality")}
          >
            <div className="img-introduce development">
              <img src={Logistic} alt="development" />
            </div>
            <div className="desc-introduce development">
              {Translate("hospitality")}
            </div>
            <div className="overlay">
              <img src={HostapilityHover} alt="CharityHover" />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Introduce;
