import { Col, Row } from "antd";
import React from "react";
import { Translate } from "../../function/Common";
import "./branding.scss";

import BrandingCompany from "../../images/branding-company.png";
function Branding() {
  return (
    <div className="brandings-container">
      <div className="branding-image">
        <div>
          <Row gutter={20} justify="center" className="branding-content">
            <Col
              xxl={5}
              lg={6}
              sm={7}
              xs={13}
              className="branding-title-container"
            >
              <div className="title branding-title">{Translate("create")}</div>
              <div className="line-divider"></div>
              <div className="desc branding-title">
                {Translate("createdesc")}
              </div>
            </Col>

            <Col
              xxl={5}
              lg={6}
              sm={7}
              xs={13}
              className="branding-title-container"
            >
              <div className="title branding-title">{Translate("value")}</div>
              <div className="line-divider"></div>
              <div className="desc branding-title">
                {Translate("valuedesc")}
              </div>
            </Col>

            <Col
              xxl={6}
              lg={6}
              sm={7}
              xs={13}
              className="branding-title-container"
            >
              <div className="title branding-title">
                {Translate("limitless")}
              </div>
              <div className="line-divider"></div>
              <div className="desc branding-title">
                {Translate("limitlessdesc")}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="branding-bottom-desc">
        <div className="branding-desc">BBI BRANDING + INTERACTIVE</div>
        <div className="branding-desc2">{Translate("bbidesc")}</div>
      </div>

      <Row justify="center" className="branding-container">
        <Col xl={8} sm={6} xs={24}>
          <img src={BrandingCompany} alt="bgbranding" />
        </Col>
        <Col className="desc-title-branding" xl={16} sm={18} xs={24}>
          <div className="title-branding">
            BERSATU BRANDING & INTERACTIVE SDN BHD
          </div>
          <div className="desc-branding"></div>

          <Row className="desc-button">
            <Col xs={24}>
              <button
                className="button"
                onClick={() =>
                  window.open("https://www.facebook.com/bbibrandinginteractive")
                }
              >
                {Translate("findoutmore")}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <div className="line-divider"></div>
        </Col>
      </Row>
    </div>
  );
}

export default Branding;
