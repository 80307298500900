import { Col, Row } from "antd";
import React from "react";
import { Translate } from "../../function/Common";
import "./development.scss";
import BinaJaya from "../../images/binajaya.png";
import BgLand from "../../images/bgland.png";
function Development() {
  return (
    <div className="development-container">
      <div className="development-image">
        <div>
          <Row gutter={20} justify="center" className="developement-content">
            <Col
              xxl={5}
              lg={6}
              sm={7}
              xs={13}
              className="development-title-container"
            >
              <div className="title development-title">
                {Translate("ourpeople")}
              </div>
              <div className="line-divider"></div>
              <div className="desc development-title">
                {Translate("ourpeopledesc")}
              </div>
            </Col>

            <Col
              xxl={6}
              lg={6}
              sm={7}
              xs={13}
              className="development-title-container"
            >
              <div className="title development-title">
                {Translate("TRUSTEDDEVELOPER")}
              </div>
              <div className="line-divider"></div>
              <div className="desc development-title">
                {Translate("TRUSTEDDEVELOPERDESC")}
              </div>
            </Col>

            <Col
              xxl={6}
              lg={6}
              sm={7}
              xs={13}
              className="development-title-container"
            >
              <div className="title development-title">
                {Translate("COMMITMENT")}
              </div>
              <div className="line-divider"></div>
              <div className="desc development-title">
                {Translate("COMMITMENTDESC")}
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="development-bottom-desc">
        <div className="development-desc">{Translate("development")}</div>
        <div className="development-desc2">{Translate("developmentdesc")}</div>
      </div>

      <Row justify="center" className="developer-container">
        <Col xl={8} sm={6} xs={24} className="binajaya-image">
          <img src={BinaJaya} alt="binajaya" />
        </Col>
        <Col className="desc-title-developer" xl={16} sm={18} xs={24}>
          <div className="title-developer">M&D BINA JAYA SDN BHD</div>
          <div className="desc-developer">{Translate("developerdesc1")}</div>
        </Col>
      </Row>

      <Row justify="center" className="developer-container">
        <Col xl={8} sm={6} xs={24}>
          <img src={BgLand} alt="bgland" />
        </Col>
        <Col className="desc-title-developer" xl={16} sm={18} xs={24}>
          <div className="title-developer">BG LAND DEVELOPMENT SDN BHD</div>
          <div className="desc-developer">{Translate("developerdesc2")}</div>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <div className="line-divider"></div>
        </Col>
      </Row>
    </div>
  );
}

export default Development;
