export const language = {
  en: {
    English: "EN",
    Chinese: "CN",
    Malay: "BM",
    Home: "HOME",
    aboutus: "ABOUT US",
    ourbusiness: "OUR BUSINESS",
    development: "DEVELOPMENT",
    branding: "BRANDING",
    plantation: "PLANTATION",
    hospitality: "HOSPITALITY",
    logistic: "LOGISTIC",
    charity: "CHARITY",
    contactus: "CONTACT US",
    build: "Build",
    fortommorow: "For Tommorow",
    BersatuGrouparepoweredbyapassionateteamthataspiretobringamazingconcepttolikeDrivenbyourslogonofBuildForTommorowweintenttobringdevelopmentanddeliversustainablefuturestoourlocalcommunities:
      "Bersatu Group are powered by a passionate team that aspire to bring amazing concept to like. Driven by our slogon of Build For Tommorow, we intent to bring development and deliver sustainable futures to our local communities",
    location: "Location",
    businesshour: "Business Hour",
    mondaytosaturday: "Monday To Saturday",
    create: "CREATE",
    createdesc: "Create remarkable brands that influence culture and consumer.",
    value: "VALUE",
    valuedesc:
      "Whether you are a large business or just starting out, we have something for everyone. BBI Branding + Interactive emphasize on communication with clients and strive to deliver value and brand experiences that enable your brands to grow stronger.",
    limitless: "LIMITLESS",
    limitlessdesc:
      "Creativity and power of action are the key element to make an idea works. Therefore, we establish positive and integrityenvironment to stimulate our team’s potential.",
    bbidesc:
      "Tailored stand out branding strategies and bring the concept into live.",
    findoutmore: "Find Out More",
    ysdesc:
      "Social responsibility had always being an expansion of our core values.",
    FOODDISTRIBUTIONPROGRAMME: "FOOD DISTRIBUTION PROGRAMME",
    FOODDISTRIBUTIONPROGRAMMEDESC:
      "Food Distribution Programme was set up as an initiative to help the needy and B40 group.",
    FACEMASKMEDICALSUPPLIESPROGRAMME: "FACE MASK & MEDICAL SUPPLIES PROGRAMME",
    FACEMASKMEDICALSUPPLIESPROGRAMMEDESC:
      "During COVID-19 pandemic, YS Charity had supports the local community through face mask donations to keep the needy safe from the spread of virus.",
    BLOODDONATIONEVENT: "BLOOD DONATION EVENT",
    BLOODDONATIONEVENTDESC:
      "Your donated blood saves one’s life. To ensure that needy has the access to it, YS Charity regularly organize blood donation event and spread the awareness and importance of blood donation.",
    contactusdesc: "Send us a message. Our team are ready to serve your needs",
    ouroffice: "Our Office",
    ourpeople: "OUR PEOPLE",
    ourpeopledesc:
      "Our team is formed by a group of motivated senior architects, senior engineer, M&E engineer and supported by a team of experienced project and site manager.",
    TRUSTEDDEVELOPER: "TRUSTED DEVELOPER",
    TRUSTEDDEVELOPERDESC:
      "Tailored remarkable design, cost-effective construction solutions and control over timely delivery.",
    COMMITMENT: "COMMITMENT",
    COMMITMENTDESC:
      "At Bersatu Group development, we value every projects and we strive to deliver quality projects and buildings.",
    developmentdesc:
      "We’re not just focus on building property, we focus on bringing development to the communities.",
    developerdesc1:
      "M&D Bina Jaya is a Malay-owned Bumiputera property development companies under Bersatu Group. The company supported by a professional team that provides premium quality development plan and custom fit solutions to maximize the value of the land.",
    developerdesc2:
      "BG Land Development is a subsidiary property development company established by Bersatu Group. The company specialized in building structures and develop constructions planning in both commercial and private sectors.",
    OURPRINCIPLE: "OUR PRINCIPLE",
    WESERVE: "WE SERVE",
    TRUSTWORTHY: "TRUSTWORTHY",
    OURPRINCIPLEDESC:
      "Delivering exceptional services and memorable experience.",
    WESERVEDESC:
      "Bersatu Group hospitality deliver quality & consistency service, make sure our customer enjoy our service and always come back to us.",
    TRUSTWORTHYDESC:
      "Customer’s wellness is our priority thus we strictly follow the sanitary codes to ensure our customers feel secure.",
    hospitalitydesc:
      "Delivering exceptional services and memorable experience.",
    hospitaliterdesc:
      "A stay that fulfil your basic necessity with flexible guest check in and free covered parking lot.",
    YOURBUSINESSCOMPANION: "YOUR BUSINESS COMPANION",
    YOURBUSINESSCOMPANIONDESC:
      "BG Logistics provide personalized logistics planning according to your needs to maximize your business revenue.",
    SERVICES: "SERVICES",
    SERVICESDESC:
      "From logistics services of supply chain to moving and relocation service, we fulfill all of your requirements.",
    FLEXIBLE: "FLEXIBLE AND REALIABLE",
    FLEXIBLEDESC:
      "Our convenient pick up service saves you the hassle and we promise to handle your items with care.",
    logisticdesc:
      "A logistic platform that operate 365 days a year to cater your need at its finest.",
    latestnews: "LATEST NEWS",
    latestnewsTitle: "International Badminton Tournament",
    latestnewsDesc:
      "BERSATU GROUP sponsored players to reach the top 4 in the International Badminton Series in Ipoh, Malaysia!",
    LatestnewsInfoTitle:
      "BERSATU GROUP sponsored players to reach the top 4 in the International Badminton Series in Ipoh, Malaysia!",
    LatestnewsInfoDesc:
      "Congratulations to the players sponsored by Bersatu Group, Leong Jun Kai / Leong Jun Kai successfully entered the top 4 in the 2022 Malaysia Ipoh Badminton International Series. Although it is a pity that he stopped in the semi-finals, but the outstanding performance of the players, the future matches are just around the corner! Bersatu All the members of the Group are with you, keep working hard! Keep up the good work! Come on!",
    backToList: "Back To List",
  },

  cn: {
    English: "EN",
    Chinese: "CN",
    Malay: "BM",
    Home: "首页",
    aboutus: "关于我们",
    ourbusiness: "我们的生意",
    development: "建筑",
    branding: "品牌",
    plantation: "植物",
    hospitality: "住宿",
    logistic: "运输",
    charity: "慈善",
    contactus: "联系我们",
    build: "打造",
    fortommorow: "明天",
    BersatuGrouparepoweredbyapassionateteamthataspiretobringamazingconcepttolikeDrivenbyourslogonofBuildForTommorowweintenttobringdevelopmentanddeliversustainablefuturestoourlocalcommunities:
      "Bersatu Group 由一支充满激情的团队提供支持，他们渴望带来令人惊叹的概念。在打造明天口号的推动下，我们打算为当地社区带来发展和可持续发展的未来",
    location: "地址",
    businesshour: "营业时间",
    mondaytosaturday: "星期一至星期六",
    create: "创造",
    createdesc: "创建影响文化和消费者的卓越品牌.",
    value: "价值",
    valuedesc:
      "无论您是一家大型企业还是刚刚起步，我们都能为每个人提供一些东西。 BBI Branding + Interactive 强调与客户的沟通，努力提供价值和品牌体验，使您的品牌变得更强大.",
    limitless: "无限",
    limitlessdesc:
      "创造力和行动力是使想法奏效的关键因素。因此，我们建立积极和诚信的环境来激发我们团队的潜力.",
    bbidesc: "量身定制脱颖而出的品牌战略，并将概念付诸实践.",
    findoutmore: "了解更多",
    ysdesc: "社会责任一直是我们核心价值观的延伸.",
    FOODDISTRIBUTIONPROGRAMME: "食品分配计划",
    FOODDISTRIBUTIONPROGRAMMEDESC:
      "食品分发计划的设立是为了帮助有需要的人和 B40 群体.",
    FACEMASKMEDICALSUPPLIESPROGRAMME: "口罩和医疗用品计划",
    FACEMASKMEDICALSUPPLIESPROGRAMMEDESC:
      "在 COVID-19 大流行期间，YS Charity 通过捐赠口罩来支持当地社区，以保护有需要的人免受病毒传播.",
    BLOODDONATIONEVENT: "献血活动",
    BLOODDONATIONEVENTDESC:
      "您捐献的血液可以挽救一个人的生命。为确保有需要的人能够获得捐血，YS Charity 定期举办捐血活动，传播捐血的意识和重要性.",
    contactusdesc: "给我们发信息。我们的团队随时准备满足您的需求.",
    ouroffice: "我们的办公室",
    ourpeople: "我们的员工",
    ourpeopledesc:
      "我们的团队由一群积极进取的高级建筑师、高级工程师、机电工程师组成，并由经验丰富的项目和现场经理团队提供支持.",
    TRUSTEDDEVELOPER: "值得信赖的开发商",
    TRUSTEDDEVELOPERDESC:
      "量身定制卓越的设计、具有成本效益的施工解决方案以及对及时交付的控制.",
    COMMITMENT: "承诺",
    COMMITMENTDESC:
      "在 Bersatu Group 的开发中，我们重视每一个项目，并努力提供优质的项目和建筑物.",
    developmentdesc: "我们不仅专注于建造房产，还专注于为社区带来发展。",
    developerdesc1:
      "M&D Bina Jaya 是 Bersatu Group 旗下的马来人拥有的土著产业发展公司。公司由专业团队提供支持，提供优质的开发计划和量身定制的解决方案，以最大限度地提高土地价值。",
    developerdesc2:
      "BG Land Development 是 Bersatu Group 成立的附属产业发展公司。该公司专门从事建筑结构和开发商业和私营部门的建筑规划.",
    OURPRINCIPLE: "我们的原则",
    WESERVE: "我们服务",
    TRUSTWORTHY: "值得信赖",
    OURPRINCIPLEDESC: "提供卓越的服务和难忘的体验.",
    WESERVEDESC:
      "Bersatu Group 热情好客提供优质和一致的服务，确保我们的客户享受我们的服务并始终回到我们身边.",
    TRUSTWORTHYDESC:
      "客户的健康是我们的首要任务，因此我们严格遵守卫生规范，以确保我们的客户感到安全.",
    hospitalitydesc: "提供卓越的服务和难忘的体验",
    hospitaliterdesc: "灵活的宾客入住和免费室内停车场可满足您的基本需求",
    YOURBUSINESSCOMPANION: "您的商务伙伴",
    YOURBUSINESSCOMPANIONDESC:
      "北控物流根据您的需求提供个性化的物流规划，最大化您的业务收益.",
    SERVICES: "服务",
    SERVICESDESC: "从供应链物流服务到搬迁服务，我们满足您的所有需求.",
    FLEXIBLE: "灵活可靠",
    FLEXIBLEDESC: "我们方便的取件服务为您省去麻烦，我们承诺会小心处理您的物品.",
    logisticdesc: "一年 365 天全天候运营的物流平台，以最好的方式满足您的需求.",
    latestnews: "最新消息",
    latestnewsTitle: "国际羽毛球赛",
    latestnewsDesc:
      "BERSATU GROUP 赞助球员在马来西亚怡保羽毛球国际系列赛打进4强!",
    LatestnewsInfoTitle:
      "BERSATU GROUP 赞助球员在马来西亚怡保羽毛球国际系列赛打进4强!",
    LatestnewsInfoDesc:
      "恭喜Bersatu Group赞助球员 , 梁峻凯 / Leong Jun Kai在2022年马来西亚怡保羽毛球国际赛系列赛,成功打进4强,虽遗憾止步于半决赛,但球员出色的表现,在往后的赛事指日可待! Bersatu Group 全体人员羽你同在,继续努力!再接再厉!加油!",
    backToList: "回到前页面",
  },

  my: {
    English: "EN",
    Chinese: "CN",
    Malay: "BM",
    Home: "Home",
    aboutus: "Tentang Kami",
    ourbusiness: "PERNIAGAAN KAMI",
    development: "PEMBANGUNAN",
    branding: "PENJENAMAAN",
    plantation: "PERLADANGAN",
    hospitality: "HOSPITALITI",
    logistic: "LOGISTIK",
    charity: "AMAL",
    contactus: "HUBUNGI KAMI",
    build: "Bina",
    fortommorow: "Untuk Esok",
    BersatuGrouparepoweredbyapassionateteamthataspiretobringamazingconcepttolikeDrivenbyourslogonofBuildForTommorowweintenttobringdevelopmentanddeliversustainablefuturestoourlocalcommunities:
      "Kumpulan Bersatu dikuasakan oleh pasukan bersemangat yang bercita-cita untuk membawa konsep yang menakjubkan untuk disukai. Didorong oleh slogan Build For Tommorow kami, kami berhasrat untuk membawa pembangunan dan menyampaikan masa depan yang mampan kepada komuniti setempat kami",
    location: "Lokasi",
    businesshour: "Waktu Perniagaan",
    mondaytosaturday: "Isnin Hingga Sabtu",
    create: " CIPTA",
    createdesc:
      "Cipta jenama luar biasa yang mempengaruhi budaya dan pengguna.",
    value: "NILAI",
    valuedesc:
      "Sama ada anda perniagaan yang besar atau baru memulakan perniagaan, kami mempunyai sesuatu untuk semua orang. Penjenamaan BBI + Interaktif menekankan pada komunikasi dengan pelanggan dan berusaha untuk menyampaikan nilai dan pengalaman jenama yang membolehkan jenama anda berkembang lebih kukuh.",
    limitless: "TAK TERHAD",
    limitlessdesc:
      "Kreativiti dan kuasa tindakan adalah elemen utama untuk menjadikan sesuatu idea itu berfungsi. Oleh itu, kami mewujudkan persekitaran yang positif dan berintegriti untuk merangsang potensi pasukan kami.",
    bbidesc:
      "Strategi penjenamaan terserlah yang disesuaikan dan membawa konsep itu ke dalam hidup.",
    findoutmore: "Ketahui Lebih Lanjut",
    ysdesc:
      "Tanggungjawab sosial sentiasa menjadi pengembangan nilai teras kami.",
    FOODDISTRIBUTIONPROGRAMME: "PROGRAM PENGAGIHAN MAKANAN",
    FOODDISTRIBUTIONPROGRAMMEDESC:
      "Program Pengagihan Makanan diwujudkan sebagai inisiatif untuk membantu golongan yang memerlukan dan golongan B40.",
    FACEMASKMEDICALSUPPLIESPROGRAMME: "Program Topeng & Bekalan Perubatan",
    FACEMASKMEDICALSUPPLIESPROGRAMMEDESC:
      "Semasa pandemik COVID-19, YS Charity telah menyokong komuniti setempat melalui sumbangan topeng muka untuk memastikan mereka yang memerlukan selamat daripada penularan virus.",
    BLOODDONATIONEVENT: "ACARA DERMA DARAH",
    BLOODDONATIONEVENTDESC:
      "Darah yang didermakan anda menyelamatkan nyawa seseorang. Untuk memastikan mereka yang memerlukan mendapat akses kepadanya, YS Charity kerap menganjurkan acara derma darah dan menyebarkan kesedaran dan kepentingan menderma darah.",
    contactusdesc:
      "Hantar mesej kepada kami. Pasukan kami sedia melayani keperluan anda",
    ouroffice: "Pejabat Kami",
    ourpeople: "STAF KAMI",
    ourpeopledesc:
      "Pasukan kami dibentuk oleh sekumpulan arkitek kanan yang bermotivasi, jurutera kanan, jurutera M&E dan disokong oleh pasukan pengurus projek dan tapak yang berpengalaman.",
    TRUSTEDDEVELOPER: "PEMAJU YANG DIPERCAYAI",
    TRUSTEDDEVELOPERDESC:
      "Reka bentuk luar biasa yang disesuaikan, penyelesaian pembinaan kos efektif dan kawalan ke atas penghantaran tepat pada masanya.",
    COMMITMENT: "KOMITMEN",
    COMMITMENTDESC:
      "Di pembangunan Kumpulan Bersatu, kami menghargai setiap projek dan kami berusaha untuk menyampaikan projek dan bangunan yang berkualiti.",
    developmentdesc:
      "Kami bukan hanya menumpukan pada membina harta, kami memberi tumpuan untuk membawa pembangunan kepada masyarakat.",
    developerdesc1:
      "M&D Bina Jaya ialah syarikat pembangunan hartanah Bumiputera milik Melayu di bawah Kumpulan Bersatu. Syarikat yang disokong oleh pasukan profesional yang menyediakan pelan pembangunan kualiti premium dan penyelesaian yang sesuai untuk memaksimumkan nilai tanah.",
    developerdesc2:
      "BG Land Development ialah anak syarikat pembangunan hartanah yang ditubuhkan oleh Bersatu Group. Syarikat itu mengkhusus dalam membina struktur dan membangunkan perancangan pembinaan dalam kedua-dua sektor komersial dan swasta.",
    OURPRINCIPLE: "PRINSIP KITA",
    WESERVE: "KAMI BERKHIDMAT",
    TRUSTWORTHY: "DIPERCAYAI",
    OURPRINCIPLEDESC:
      "Menyediakan perkhidmatan yang luar biasa dan pengalaman yang tidak dapat dilupakan.",
    WESERVEDESC:
      "Hospitaliti Bersatu Group memberikan perkhidmatan yang berkualiti & konsisten, pastikan pelanggan kami menikmati perkhidmatan kami dan sentiasa kembali kepada kami.",
    TRUSTWORTHYDESC:
      "Kesihatan pelanggan adalah keutamaan kami oleh itu kami mematuhi kod kebersihan untuk memastikan pelanggan kami berasa selamat.",
    hospitalitydesc:
      "Menyampaikan perkhidmatan yang luar biasa dan pengalaman yang tidak dapat dilupakan.",
    hospitaliterdesc:
      "Penginapan yang memenuhi keperluan asas anda dengan daftar masuk tetamu yang fleksibel dan tempat letak kereta berbumbung percuma.",
    YOURBUSINESSCOMPANION: "TEMAN PERNIAGAAN ANDA",
    YOURBUSINESSCOMPANIONDESC:
      "BG Logistics menyediakan perancangan logistik yang diperibadikan mengikut keperluan anda untuk memaksimumkan hasil perniagaan anda.",
    SERVICES: "PERKHIDMATAN",
    SERVICESDESC:
      "Daripada perkhidmatan logistik rantaian bekalan kepada perkhidmatan pemindahan dan penempatan semula, kami memenuhi semua keperluan anda.",
    FLEXIBLE: "FLEKSIBEL DAN BOLEH NYATA",
    FLEXIBLEDESC:
      "Perkhidmatan pengambilan kami yang mudah menjimatkan kerumitan anda dan kami berjanji untuk mengendalikan barangan anda dengan berhati-hati.",
    logisticdesc:
      "Platform logistik yang beroperasi 365 hari setahun untuk memenuhi keperluan anda dengan sebaik mungkin.",
    latestnews: "BERITA TERKINI",
    latestnewsTitle: "Kejohanan Badminton Antarabangsa",
    latestnewsDesc:
      "KUMPULAN BERSATU menaja pemain untuk mencapai 4 terbaik dalam Siri Badminton Antarabangsa di Ipoh, Malaysia!",
    LatestnewsInfoTitle:
      "KUMPULAN BERSATU menaja pemain untuk mencapai 4 terbaik dalam Siri Badminton Antarabangsa di Ipoh, Malaysia!",
    LatestnewsInfoDesc:
      "Tahniah diucapkan kepada pemain tajaan Kumpulan Bersatu, Leong Jun Kai / Leong Jun Kai berjaya memasuki kelompok 4 terbaik dalam Siri Antarabangsa Badminton Malaysia Ipoh 2022. Walaupun sayang sekali beliau berhenti di separuh akhir, namun prestasi cemerlang pasukan pemain, perlawanan akan datang semakin hampir! Bersatu Semua ahli Kumpulan bersama anda, teruskan bekerja keras! Teruskan kerja yang baik! Ayuh!",
    backToList: "Kembali",
  },
};
