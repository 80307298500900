import { Card, Col, Row } from "antd";
import React, { useState } from "react";
import { Translate } from "../../function/Common";
import "./latestnews.scss";
import { CCarousel, CCarouselItem, CImage } from "@coreui/react";
import { useHistory } from "react-router-dom";

function Latestnews() {
  const history = useHistory();
  const ArrLatestNewsImage = [];
  return (
    <div className="latest-new-container">
      <Row justify="center">
        <Col xl={8} lg={12} xs={23}>
          <div
            class="container"
            onClick={() => history.push("/latestnews/info")}
          >
            <div class="wrapper">
              <div class="banner-image"> </div>
              <h1>{Translate("latestnewsTitle")}</h1>
              <p>{Translate("latestnewsDesc")}</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Latestnews;
