import { Button, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import "./appointment.scss";

const { TextArea } = Input;

function Appointment() {
  return (
    <div className="appointment-background">
      <div className="appointment-container">
        <Row>
          <Col xs={24}>
            <div className="appointment-title">Make an Appointment With Us</div>
          </Col>
        </Row>
        <div className="appointment-input">
          <Form layout="vertical">
            <Row gutter={20}>
              <Col sm={12} xs={24}>
                <div className="">
                  <Form.Item label="Name">
                    <Input />
                  </Form.Item>
                </div>
              </Col>

              <Col sm={12} xs={24}>
                <div className="">
                  <Form.Item label="Mobile Phone Number">
                    <Input />
                  </Form.Item>
                </div>
              </Col>

              <Col sm={12} xs={24}>
                <div className="">
                  <Form.Item label="Service">
                    <Select>
                      <Select.Option value="--">--</Select.Option>
                      <Select.Option value="charity">Charity</Select.Option>
                      <Select.Option value="development">
                        Development
                      </Select.Option>
                      <Select.Option value="logistic">Logistic</Select.Option>
                      <Select.Option value="plantation">
                        Plantation
                      </Select.Option>
                      <Select.Option value="branding">
                        Branding & Interior Consultation
                      </Select.Option>
                      <Select.Option value="hospitality">
                        Hospitality
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col sm={12} xs={24}>
                <div className="">
                  <Form.Item label="Email">
                    <Input />
                  </Form.Item>
                </div>
              </Col>

              <Col sm={12} xs={24}>
                <div className="">
                  <Form.Item label="Message">
                    <TextArea style={{ height: 150 }} />
                  </Form.Item>
                </div>
              </Col>

              <Col xxl={4} sm={8} xs={12}>
                <Form.Item label="123">
                  <div className="submit-button">
                    <button type="submit">Submit</button>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default Appointment;
