import { Col, Row } from "antd";
import React from "react";
import { Translate } from "../../function/Common";
import "./hospitality.scss";

import HospitalityCompany from "../../images/hospitality-company.png";
function Hospitality() {
  return (
    <div className="hospitalitys-container">
      <div className="hospitality-image">
        <div>
          <Row gutter={20} justify="center" className="hospitality-content">
            <Col
              xxl={5}
              lg={6}
              sm={7}
              xs={13}
              className="hospitality-title-container"
            >
              <div className="title hospitality-title">
                {Translate("OURPRINCIPLE")}
              </div>
              <div className="line-divider"></div>
              <div className="desc hospitality-title">
                {Translate("OURPRINCIPLEDESC")}
              </div>
            </Col>

            <Col
              xxl={5}
              lg={6}
              sm={7}
              xs={13}
              className="hospitality-title-container"
            >
              <div className="title hospitality-title">
                {Translate("WESERVE")}
              </div>
              <div className="line-divider"></div>
              <div className="desc hospitality-title">
                {Translate("WESERVEDESC")}
              </div>
            </Col>

            <Col
              xxl={5}
              lg={6}
              sm={7}
              xs={13}
              className="hospitality-title-container"
            >
              <div className="title hospitality-title">
                {Translate("TRUSTWORTHY")}
              </div>
              <div className="line-divider"></div>
              <div className="desc hospitality-title">
                {Translate("TRUSTWORTHYDESC")}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="hospitality-bottom-desc">
        <div className="hospitality-desc">{Translate("hospitality")}</div>
        <div className="hospitality-desc2">{Translate("hospitalitydesc")}</div>
      </div>

      <Row justify="center" className="hospitality-container">
        <Col xl={8} sm={6} xs={24}>
          <img src={HospitalityCompany} alt="bghospitality" />
        </Col>
        <Col className="desc-title-hospitality" xl={16} sm={18} xs={24}>
          <div className="title-hospitality">BERSATU SMART HOTEL SDN BHD</div>
          <div className="desc-hospitality">
            {Translate("hospitaliterdesc")}
          </div>

          <Row className="desc-button">
            <Col xs={24}>
              <button
                className="button"
                onClick={() =>
                  window.open("https://www.facebook.com/bghomestays")
                }
              >
                {Translate("findoutmore")}
              </button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <div className="line-divider"></div>
        </Col>
      </Row>
    </div>
  );
}

export default Hospitality;
